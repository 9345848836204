@import "../../colors.variables";

.alert-dialog {
    .dialog-btn-primary, .dialog-btn-secondary {
        &.btn-danger {
            background-color: $ms-color-red;
            &:hover, &:active { background-color: $ms-color-redDark; }
            &.dialog-btn-secondary { color: $ms-color-white; }
        }

        &.btn-success {
            background-color: $ms-color-green;
            &:hover, &:active { background-color: $ms-color-greenDark; }
            &.dialog-btn-secondary { color: $ms-color-white; }
        }
    }
}