.Jumbotron{
    margin-left:5px;
    box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#helpBtn {
    background-color: white;color:black;margin:10px 10px
  }
  
  #getStartedBtn{
    background-color: white;color:black;margin:10px 10px
  }
  
  #banner {
    background:linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)),
    url("https://apimgmtstty38v78nlowradd.blob.core.windows.net/content/MediaLibrary/images/work.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%; 
    min-height: 300px;
    display:block;
    width:100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0px;
  }
  
  #banner h1, #banner p {
    margin: 0;
    padding: 10px;
    color: white;
    font-weight: 580;
  }

  .inline-headers h2, .inline-headers h4 {
    display: inline-block;
    vertical-align: baseline;
    bottom: 0;
    font-size: 1.25rem;
  }

  .approval{
    background: #527d15;
    padding: .25rem .5rem;
    display: inline-block;
    color: white;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
  }

  /*.rejected{
    background: #e50000;
    padding: .25rem .5rem;
    display: inline-block;
    color: white;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
  } */

  .newrequest{
      background-color: #004b6b;
  }

  /*.pending {
    background: orange;
    padding: .25rem .5rem;
    display: inline-block;
    color: white;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;  
  }*/

 

  .upcoming,.servicehealth , .qos, .fcm ,.approvedrequests ,.createdrequests,.pending
  {
    font-size: 14px;
    padding: 2rem 0rem;
    table-layout : fixed;
    width: 100%;
    border: 0;
    top: 1px;
  }

  .rejected
  {
    font-size: 14px;
    padding: 0.5rem 0rem;
    table-layout : fixed;
    width: 100%;
    border: 0;
    top: 1px;
  }


  .childtable
  {
    font-size: 14px;
    padding: 2rem 0rem;
    table-layout : fixed;
    width: 80%;
    border: 0;
    top: 1px;
  }

  .childservicehealth
  {
    font-size: 14px;
    padding: 2rem 0rem;
    table-layout : fixed;
    width: 100%;
    border: 0;
    top: 1px;
  }

  .table.dataTable {
    border-collapse: collapse;
    margin-top: 4px;
  }

  table.dataTable thead th {
    border-bottom: 0;
    /* text-align: center; */
  }
  table.dataTable tfoot th {
    border-top: 0;
  }

  .upcoming td,.servicehealth td , .qos td , .fcm td ,.approvedrequests td ,.createdrequests td,.pending td,.rejected td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .eventbuttons {
    float: right;
    line-height: 1.2;
    border-radius: 0.5rem;
    padding: 0.25em;
    /* margin-left: 660px; */
  }
  .approved{
    display: flex;
    text-align: center;
  }

  .servicehealth td, .servicehealth th , .qos th, .fcm th , .approvedrequests th ,.createdrequests th {
    border: none;
}

#myTabs .nav-link.active, #myTabs .show >.nav-link {
  color:#495057;
  background-color: #fff;
  border-color: #006ee5;
}

#pills-tab .nav-link.active {
  color: #fff;
  background-color: #006ee5!important;
}

#pills-tab .show >.nav-link {
  color: #006ee5;
  background-color: #fff;
}

.table-filter-container {
  text-align: right;
}

.dataTables_wrapper .dataTables_paginate .paginate_button
    {
        min-width: 0.1em;
    }

    td.details-control:before {
    
      font-family: FontAwesome;
      content: "\F054";
      
      }
    
      tr.shown td.details-control:before {
      
        font-family: FontAwesome;
        content: "\F078";
        
    }

    .portal-name{ color : #0078D4!important}

    .toolbar {
      color : #0078D4!important;
      margin-bottom: .5rem;
      font-weight: 500;
      line-height: 1.2;
      float: left;
    }

    .hasTooltip span {
      display: none;
      color: #000;
      text-decoration: none;
      padding: 3px;
  }
  
  .hasTooltip:hover span {
      display: block;
      position: absolute;
      background-color: #FFF;
      border: 1px solid #CCC;
      margin: 2px 10px;

  }

  .note {
    font-size: 12px;
  }

  .nav-tabs .badge{
    position: absolute;
}
.btn-failure
{
    background-color: red;
    border-color: red;
    color:#13171a;
}
.btn-success
{
    background-color: #208636;
    border-color: #208636;
}
.btn-primary
{
  background-color: #006ee5;
  border-color: #006ee5;
}
a
{
  color: #006ee5;
}
a.rbc-show-more 
{
  background-color: #dcffff
}

#Select
{
  color: #727272;
}
a.page-link
{
  color: #006ee5;
}
div.css-1wa3eu0-placeholder
{
  color: #727272;
  background-color: #fefefe;
}
div.css-107lb6w-singleValue
{
  color: #6e6e6e;
  background-color: #f2f2f2;
}

/* Make table responsive */
.event-table {
  overflow-x: auto;
}

/* Space out cells */
.he-table td, .he-table th {
  padding: 10px;
}





