.react-flow__node-default,.react-flow__node-input,.react-flow__node-output,.react-flow__node-selectorNode
{
    
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    color: #222;
    font-size: 12px;
    padding: 10px;
    text-align: center;
    width: 150px;
}