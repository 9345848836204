#body-row {
    margin-left:0;
    margin-right:0;    
}
#sidebar-container {
    min-height: 90vh;   
    background-color:#E5E5E5;
    padding: 0;
    /*min-width: 35vh;*/
   
    
}

.bg-dark {
    background-color: #E5E5E5!important;
}
.sticky-offset {
    top: 63px;
}

/* Sidebar sizes when expanded and expanded */
.sidebar-expanded {
    width: 230px;
}
.sidebar-collapsed {
    width: 60px;
}

/* Menu item*/
#sidebar-container .list-group a {
    height: 70px;
    color:black;
}

/* Submenu item*/
#sidebar-container .list-group .sidebar-submenu a {
    height: 45px;
    padding-left: 30px;
    color:black;
}
.sidebar-submenu {
    font-size: 0.9rem;
}

#sidebar-container .list-group a:focus span {color: #fff !important; }

#sidebar-container .list-group a:hover span {color: #fff !important; }

/* Separators */
.sidebar-separator-title {
    background-color:#E5E5E5;
    height: 35px;
}
.sidebar-separator {
    background-color:#E5E5E5;
    height: 25px;
}
.logo-separator {
    background-color:#E5E5E5;  
    height: 60px;
}

/* Closed submenu icon */
#sidebar-container .list-group .list-group-item[aria-expanded="false"] .submenu-icon::after {
  content: " \f0d7";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}

#sidebar-container ul.nav a:hover { color: #fff !important; }
/* Opened submenu icon */
#sidebar-container .list-group .list-group-item[aria-expanded="true"] .submenu-icon::after {
  content: " \f0da";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;

}


#logout_sidebar_button {
    position: absolute;
    display: inline-block;
    bottom: 0px;
}

#collapse{
    position: fixed;
    bottom: 0;
    display: inline-block;
    width:230px;
    
}