.nav-logo {
    float: left !important;
    margin-top: -15px !important;
  }

.navbar-dark .navbar-nav .nav-link {
    color: #E3E3E3;
}

.nav-link{
  padding-left: 0.75rem;
  background-color: transparent;
}

.bg-primary {
  background-color: #006ee5!important;
}

.dropdown-toggle { color: white;}


.feedbackInput {
  padding: 16px;
  width: 380px;
}

.feedbackOption {
  background-color: #F5F5F5;
  border-radius: 6px;
  padding: 4px 16px;
  font-size: 13px;
  line-height: 20px;
  color: #666;
  margin-bottom: 16px;
  cursor: pointer;
  display: table;
}




