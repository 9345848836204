.react-bootstrap-table {
    /* display: block; */
    /* width: 100%; */
   /* overflow-x: auto;
    overflow-y: scroll;   */
    /* overflow: auto;
    display: block;
    table-layout: auto !important;
    overflow-y: auto; */
  max-height: 400px;
  overflow: auto;
  min-height: 100px;
  table-layout: auto !important;
  
  
  }

  .react-bootstrap-table table th,
  .react-bootstrap-table table td {
  white-space: normal;
  background-color: white;
  overflow: hidden;
  text-overflow: ellipsis;
 
  } 



 

  

 