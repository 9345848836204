.App {
  text-align: center;
  /* overflow: auto;  */
  
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.form-group.required .control-label:after {content:"*";color:red;}

.my-fixed-button {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rbc-date-cell.rbc-off-range {
  color:#66665e;
  } 

  .rdt {
    position: relative;
  }
  .rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;
  }
  .rdtOpen .rdtPicker {
    display: block;
  }
  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
  }
  
  .rdtPicker .rdtTimeToggle {
    text-align: center;
  }

  
  .rdtPicker table {
    width: 100%;
    margin: 0;
  }
  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    height: 28px;
  }
  .rdtPicker td {
    cursor: pointer;
  }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
  }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: #999999;
  }
  .rdtPicker td.rdtToday {
    position: relative;
  }
  .rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
  }
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  
  .rdtPicker td span.rdtOld {
    color: #999999;
  }
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
  }
  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
  }
  .rdtPicker th.rdtSwitch {
    width: 100px;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
  }
  
  .rdtPrev span,
  .rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  
  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker thead tr:first-child th {
    cursor: pointer;
  }
  .rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
  }
  
  .rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
  }
  
  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
  }
  .rdtPicker button:hover {
    background-color: #eee;
  }
  
  .rdtPicker thead button {
    width: 100%;
    height: 100%;
  }
  
  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee;
  }
  
  .rdtCounters {
    display: inline-block;
  }
  
  .rdtCounters > div {
    float: left;
  }
  
  .rdtCounter {
    height: 100px;
  }
  
  .rdtCounter {
    width: 40px;
  }
  
  .rdtCounterSeparator {
    line-height: 100px;
  }
  
  .rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
  
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  .rdtCounter .rdtBtn:hover {
    background: #eee;
  }
  .rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }
  
  .rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
  }
  
  .rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }
  
  .rdtTime td {
    cursor: default;
  }

  .rbc-off-range-bg {
    background : white; 
  }


.legend { list-style-type:none;}
.legend li { float: left; margin-right: 20px; }
.legend span { border: 1px solid #ccc; float: left; width: 15px; height: 15px; margin: 2px; }
/* your colors */
.legend .review { background-color:rgb(73, 73, 141); }
.legend .approved { background-color:#28a745; }
.legend .adhoc { background-color: lightcoral }
.legend .shutdown { background-color:black}
.legend .inventory { background-color:#8d4957}
/*.legend .saplockdown{background-color: #277B59}
.legend .financefreeze{background-color: #F72D12}
.legend .holidays{background-color: #265845}
.legend .aqr{background-color: #378475}
.legend .fqr{background-color: #A145C0 }
.legend .mqr{background-color: #1C8250 }
.legend .nqr{background-color: #74821C }
.legend .monthlyrelease{background-color: #32BCE0} */
.legend .financefreeze{background-color: #de2710}
.legend .monthquarterendrestriction{background-color: darkgreen;}
.legend .plantholiday{background-color: darkblue;}
.legend .publicholiday{background-color: #af5f00 ;}
.legend .production{background-color: green;}
.legend .partialproduction{background-color: purple;}
.legend .ot{background-color:#007ba6;}
.legend .physicalinventorycount{background-color: #af5f00;}
.legend .holiday{background-color: black;}



.informational
{
  height: 20px;
}


#external-events {
  float: left;
  width: 240px;
  margin-top: 5px;
  border: 0px solid #ccc;
  text-align: left;
  /* border: 1px solid red; */
  margin-right: 5px;
}

.calendar-container{
  /* border: 3px solid blue; */
  width: 100%;
  margin: 0 auto;
}

.render-container {
  overflow: hidden;
  font-size: 14px;
}

.title{
  text-align: left;
  width: 100%;
  margin-left: 270px;
  margin-bottom: 60px;
}

.toolbar{
  margin-top: 10px;
  margin-bottom: 60px;
}

@media screen and (max-width: 913px) {
  .render-container {
    flex-direction: column;
  }
  
  .external-events-container,
  .big-calendar-container {
    width: 100%;
  }
}

/* for screen sizes greater than 1600px */
@media screen and (min-width: 1600px) {
  #external-events {
    margin-right: 55px;
  }
}
